import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Minerals from "../components/factors/minerals"
import Nutrients from "../components/factors/nutrients"
import Vitamins from "../components/factors/vitamins"
// import { StaticImage } from "gatsby-plugin-image"

const TABS = {
  minerals: <Minerals />,
  nutrients: <Nutrients />,
  vitamins: <Vitamins />,
}

export default function App({ location }) {
  const [selectedTab, setSelectedTab] = useState("nutrients")

  return (
    <Layout location={location}>
      <SEO title="NuQ Factors" />
      <div className="page-content nuq-factors">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-content-title">
                <h1 className="section-title text-d-cyan mr-4">
                  NuQ <span className="title-underline">Factors</span>
                </h1>
                <p className="lead mt-4 mb-5">
                  Rather than counting calories we emphasize the importance of
                  nutrients as listed below along with their respective
                  quantities as specified by the
                  <a
                    href="https://www.nationalacademies.org/fnb/about"
                    target="_blank"
                    className="text-underline ml-2"
                  >
                    Nutrition Board
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="factors-tabs">
                <div
                  onClick={() => setSelectedTab("nutrients")}
                  className="tabs-item nutrients mb-4"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>PRIMARY NUTRIENTS</h4>
                  </div>
                </div>
                <div
                  onClick={() => setSelectedTab("vitamins")}
                  className="tabs-item vitamins mb-4"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>VITAMINS</h4>
                  </div>
                </div>
                <div
                  onClick={() => setSelectedTab("minerals")}
                  className="tabs-item minerals"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>MINERALS</h4>
                  </div>
                </div>
                {/* <div onClick={() => setSelectedTab("")} className="tabs-item">
                  <div className="d-flex align-items-center justify-content-between">
                    <StaticImage
                      imgClassName="img-fluid"
                      src="../images/home2/tabs-default.png"
                      alt="Tabs Default"
                    />
                  </div>
                </div> */}
              </div>
              {TABS[selectedTab]}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
