import React from "react"
import { Link } from "gatsby"
import { Nav, Col, Tab, Row, Container } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const Vitamins = () => (
  <div id="factor-body">
    <Container>
      <Row>
        <Col className="p-0">
          <Tab.Container id="left-tabs-example" defaultActiveKey="">
            <Row>
              <Col lg={5} md={5} className="mb-4">
                <Nav variant="pills" className="flex-column factors-list">
                  <Nav.Item>
                    <div className="d-flex align-items-center justify-content-between px-3">
                      <h5 className="title f-900 text-d-cyan py-0">Vitamins</h5>
                      <h5 className="title f-900 text-d-cyan py-0">
                        Daily Values*
                      </h5>
                    </div>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">A</p>
                        <p className="values mr-3">900mcg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">B1</p>
                        <p className="values mr-3">1.2mg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">B2</p>
                        <p className="values mr-3">1.3mg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">B3</p>
                        <p className="values mr-3">16mg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">B5</p>
                        <p className="values mr-3">5mg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">B6</p>
                        <p className="values mr-3">1.7mg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">B7</p>
                        <p className="values mr-3">30mcg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">B9</p>
                        <p className="values mr-3">400mcg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">B12</p>
                        <p className="values mr-3">2.4mcg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">Choline</p>
                        <p className="values mr-3">550mg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">C</p>
                        <p className="values mr-3">90mg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">D</p>
                        <p className="values mr-3">900mcg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">E</p>
                        <p className="values mr-3">15mg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="title mr-auto">K</p>
                        <p className="values mr-3">120mcg</p>
                        <StaticImage
                          imgClassName="factor-learnmore-icon d-none img-fluid"
                          src="../../images/home2/learn-more.png"
                          alt="Learn More"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={5} md={4} className="mb-4">
                <Tab.Content>
                  <Tab.Pane eventKey="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="595"
                      height="514"
                      viewBox="0 0 595 514"
                      className="w-100"
                    >
                      <g
                        id="Group_73497"
                        data-name="Group 73497"
                        transform="translate(-897 -585.754)"
                      >
                        <g
                          id="Group_73493"
                          data-name="Group 73493"
                          transform="translate(898 586.754)"
                        >
                          <ellipse
                            id="Ellipse_70"
                            data-name="Ellipse 70"
                            cx="183.5"
                            cy="184"
                            rx="183.5"
                            ry="184"
                            transform="translate(114 0)"
                            fill="none"
                            stroke="#007a6e"
                            stroke-width="2"
                          />
                          <circle
                            id="Ellipse_71"
                            data-name="Ellipse 71"
                            cx="184"
                            cy="184"
                            r="184"
                            transform="translate(225 144)"
                            fill="none"
                            stroke="#01e1ca"
                            stroke-width="2"
                          />
                          <ellipse
                            id="Ellipse_72"
                            data-name="Ellipse 72"
                            cx="185"
                            cy="184"
                            rx="185"
                            ry="184"
                            transform="translate(0 144)"
                            fill="none"
                            stroke="#fab522"
                            stroke-width="2"
                          />
                        </g>
                        <g
                          id="Group_73494"
                          data-name="Group 73494"
                          transform="translate(1141.898 651.918)"
                        >
                          <text
                            id="_9_Basic"
                            data-name="9 Basic"
                            transform="translate(8.102 22.836)"
                            fill="#007a6e"
                            font-size="25"
                            font-family="Rubik-Bold, Rubik"
                            font-weight="700"
                          >
                            <tspan x="-1%" y="0">
                              Primary
                            </tspan>
                          </text>
                          <text
                            id="Nutrients"
                            transform="translate(0.102 54.836)"
                            fill="#007a6e"
                            font-size="25"
                            font-family="Lato-Medium, Lato"
                            font-weight="500"
                          >
                            <tspan x="0" y="0">
                              Nutrients
                            </tspan>
                          </text>
                        </g>
                        <g
                          id="Group_73495"
                          data-name="Group 73495"
                          transform="translate(962.233 949.808)"
                        >
                          <text
                            id="_13"
                            data-name="13"
                            transform="translate(36.767 23.946)"
                            fill="#fab522"
                            font-size="26"
                            font-family="Rubik-Bold, Rubik"
                            font-weight="700"
                          >
                            <tspan x="0" y="0">
                              13
                            </tspan>
                          </text>
                          <text
                            id="Vitamins"
                            transform="translate(-0.233 55.946)"
                            fill="#fab522"
                            font-size="25"
                            font-family="Lato-Medium, Lato"
                            font-weight="500"
                          >
                            <tspan x="0" y="0">
                              Vitamins
                            </tspan>
                          </text>
                        </g>
                        <g
                          id="Group_73496"
                          data-name="Group 73496"
                          transform="translate(1318.771 949.808)"
                        >
                          <text
                            id="_15"
                            data-name="15"
                            transform="translate(34.229 24.946)"
                            fill="#01e1ca"
                            font-size="27"
                            font-family="Rubik-Bold, Rubik"
                            font-weight="700"
                          >
                            <tspan x="0" y="0">
                              15
                            </tspan>
                          </text>
                          <text
                            id="Minerals"
                            transform="translate(0.229 55.946)"
                            fill="#01e1ca"
                            font-size="25"
                            font-family="Lato-Medium, Lato"
                            font-weight="500"
                          >
                            <tspan x="0" y="0">
                              Minerals
                            </tspan>
                          </text>
                        </g>
                        <path
                          id="Intersection_1"
                          data-name="Intersection 1"
                          d="M1.981,174.1A185.409,185.409,0,0,1,72.5,0,184.789,184.789,0,0,1,145,146.92a186.49,186.49,0,0,1-1.981,27.175,185.777,185.777,0,0,1-141.038,0Z"
                          transform="translate(1123 768.754)"
                          fill="#00ccb8"
                          stroke="#00ccb8"
                          stroke-width="2"
                        />
                        <text
                          id="NuQ37_Optimal_Nutrition"
                          data-name="NuQ Optimal Nutrition"
                          transform="translate(1194 843.754)"
                          fill="#fff"
                          font-size="25"
                          font-family="Rubik-Bold, Rubik"
                          font-weight="700"
                        >
                          <tspan x="-28.3" y="0">
                            NuQ
                          </tspan>
                          <tspan x="0" y="29"></tspan>
                          <tspan x="-48.563" y="58">
                            Optimal
                          </tspan>
                          <tspan x="-57.175" y="87">
                            Nutrition
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="first">
                    <div className="factors-list-body">
                      <div className="p-4 bg-yellow">
                        <h5 className="text-d-green f-700 pb-0">Carbs</h5>
                        <p className="text-d-green f-300">
                          The quick, brown fox jumps over a lazy dog. DJs flock
                          by when MTV ax quiz prog. Junk MTV quiz graced by fox
                          whelps. Bawds jog, flick quartz, vex nymphs. Waltz,
                          bad nymph, for quick jigs vex! Fox nymphs grab
                          quick-jived waltz. Brick quiz whangs jumpy veldt fox.
                          Bright vixens jump; dozy fowl quack. Quick
                        </p>
                      </div>
                      <div className="list-contain p-4">
                        <h5 className="text-d-green f-700 pb-0">
                          List of food that contain
                        </h5>
                        <ul>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="factors-list-body">
                      <div className="p-4 bg-yellow">
                        <h5 className="text-d-green f-700 pb-0">Cholesterol</h5>
                        <p className="text-d-green f-300">
                          The quick, brown fox jumps over a lazy dog. DJs flock
                          by when MTV ax quiz prog. Junk MTV quiz graced by fox
                          whelps. Bawds jog, flick quartz, vex nymphs. Waltz,
                          bad nymph, for quick jigs vex! Fox nymphs grab
                          quick-jived waltz. Brick quiz whangs jumpy veldt fox.
                          Bright vixens jump; dozy fowl quack. Quick
                        </p>
                      </div>
                      <div className="list-contain p-4">
                        <h5 className="text-d-green f-700 pb-0">
                          List of food that contain
                        </h5>
                        <ul>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="thrid">
                    <div className="factors-list-body">
                      <div className="p-4 bg-yellow">
                        <h5 className="text-d-green f-700 pb-0">Total Fat</h5>
                        <p className="text-d-green f-300">
                          The quick, brown fox jumps over a lazy dog. DJs flock
                          by when MTV ax quiz prog. Junk MTV quiz graced by fox
                          whelps. Bawds jog, flick quartz, vex nymphs. Waltz,
                          bad nymph, for quick jigs vex! Fox nymphs grab
                          quick-jived waltz. Brick quiz whangs jumpy veldt fox.
                          Bright vixens jump; dozy fowl quack. Quick
                        </p>
                      </div>
                      <div className="list-contain p-4">
                        <h5 className="text-d-green f-700 pb-0">
                          List of food that contain
                        </h5>
                        <ul>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                          <li>Malesuada sed </li>
                          <li>Auctor maecenas</li>
                          <li>Orci elit</li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane> */}
                </Tab.Content>
              </Col>
              <Col lg={2} md={3}>
                <div className="legends-body">
                  <h5 className="text-d-green f-700 pb-0">Legends</h5>
                  {/* <p className="text-d-green f-300 lh-24">
                    *FDA guidelines are based on a daily 2,000 calorie in take.
                  </p> */}
                  <div className="d-flex align-items-center justify-content-start mb-3 mt-4">
                    <StaticImage
                      imgClassName="img-fluid"
                      src="../../images/home2/grams.png"
                      alt="Grams"
                    />
                    <p className="ml-3 f-500 text-d-green">grams</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-start mb-3">
                    <StaticImage
                      imgClassName="img-fluid"
                      src="../../images/home2/miligrams.png"
                      alt="Miligrams"
                    />
                    <p className="ml-3 f-500 text-d-green">miligrams</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-start mb-3">
                    <StaticImage
                      imgClassName="img-fluid"
                      src="../../images/home2/micrograms.png"
                      alt="Micrograms"
                    />
                    <p className="ml-3 f-500 text-d-green">micrograms</p>
                  </div>
                  {/* <p className="text-black-50 f-300 f-lato lh-20">
                    Please click on each nutrient for a thorough explanation of
                    itsimportance to the human body and a list of the foods that
                    contain each nutrient
                  </p> */}
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Vitamins
